import React from 'react';
import techexpo1 from '../assets/techexpo-1.jpeg';
import techexpo2 from '../assets/techexpo-2.jpeg';
import techexpo6 from '../assets/techexpo-6.jpeg';

const images = [techexpo1, techexpo2, techexpo6];

const TechExpoSlider = () => {
  return (
    <div className="w-full max-w-5xl mx-auto my-8 px-4">
      {/* Header Section */}
      <div className="text-center mb-6">
        <h2 className="text-3xl sm:text-4xl font-bold">
          Generative AI Expo Speaker Announcement
        </h2>
        <p className="mt-2 text-lg text-gray-600">
          We are thrilled to announce the latest speaker at Generative AI Expo, part of the ITEXPO #TECHSUPERSHOW, Feb 11-13, 2025, Fort Lauderdale, FL.
        </p>
      </div>
      {/* Card with All Images (No gaps) */}
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="grid grid-cols-3 gap-0">
          {images.map((img, index) => (
            <div key={index} className="h-64">
              <img
                src={img}
                alt={`Tech Expo ${index + 1}`}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TechExpoSlider; 