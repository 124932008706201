// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes, FaChevronDown, FaRegClock, FaCalendarAlt, FaInfoCircle, FaMapMarkerAlt } from 'react-icons/fa';
import logoImage from '../assets/image.png'; // Ensure this path is correct

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Handle scroll to add shadow
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleNavigation = () => {
    closeMobileMenu();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  // Prevent background scroll when mobile menu is open
  useEffect(() => {
    document.body.style.overflow = isMobileMenuOpen ? 'hidden' : 'auto';
  }, [isMobileMenuOpen]);

  return (
    <>
      {/* Pre Navbar removed */}

      {/* Main Navbar */}
      <nav
        className={`fixed top-0 left-0 w-full z-50 bg-white border-b border-gray-100 shadow-sm transition-all duration-300 ease-in-out ${
          isScrolled ? 'shadow-md' : ''
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-3 flex items-center justify-between">
          {/* Logo */}
          <Link to="/" onClick={handleNavigation} className="flex items-center">
            <img
              src={logoImage}
              alt="Trussed.ai"
              className="h-3 sm:h-4 md:h-5 lg:h-6 xl:h-7 flex-shrink-0"
            />
          </Link>

          {/* Navigation Links (Center) */}
          <ul className="hidden lg:flex items-center space-x-6">
            {/* Home Link */}
            <li>
              <Link
                to="/"
                onClick={handleNavigation}
                className="relative text-gray-900 font-medium hover:text-blue-600 transition duration-300"
              >
                Home
                <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-blue-600 transition-all duration-300 hover:w-full" />
              </Link>
            </li>

            {/* Solutions Dropdown */}
            <li className="relative group">
              <button className="flex items-center text-gray-900 font-medium hover:text-blue-600 transition duration-300">
                Solutions{' '}
                <FaChevronDown className="ml-1 transition-transform duration-300 group-hover:rotate-180" />
              </button>
              <ul className="absolute left-0 mt-2 bg-white rounded-lg shadow-lg opacity-0 group-hover:opacity-100 group-hover:visible transform translate-y-2 group-hover:translate-y-0 transition-all duration-300 ease-in-out">
                <li>
                  <Link
                    to="/solutions/enterprise"
                    onClick={handleNavigation}
                    className="block px-4 py-2 text-sm text-gray-900 hover:bg-blue-50 hover:text-blue-600 transition-colors duration-200"
                  >
                    Enterprise
                  </Link>
                </li>
                <li>
                  <Link
                    to="/solutions/university"
                    onClick={handleNavigation}
                    className="block px-4 py-2 text-sm text-gray-900 hover:bg-blue-50 hover:text-blue-600 transition-colors duration-200"
                  >
                    University
                  </Link>
                </li>
              </ul>
            </li>

            {/* Company Dropdown */}
            <li className="relative group">
              <button className="flex items-center text-gray-900 font-medium hover:text-blue-600 transition duration-300">
                Company{' '}
                <FaChevronDown className="ml-1 transition-transform duration-300 group-hover:rotate-180" />
              </button>
              <ul className="absolute left-0 mt-2 bg-white rounded-lg shadow-lg opacity-0 group-hover:opacity-100 group-hover:visible transform translate-y-2 group-hover:translate-y-0 transition-all duration-300 ease-in-out">
                <li>
                  <Link
                    to="/company/about"
                    onClick={handleNavigation}
                    className="block px-4 py-2 text-sm text-gray-900 hover:bg-blue-50 hover:text-blue-600 transition-colors duration-200"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/company/contact"
                    onClick={handleNavigation}
                    className="block px-4 py-2 text-sm text-gray-900 hover:bg-blue-50 hover:text-blue-600 transition-colors duration-200"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </li>

            {/* Blog Link */}
            <li>
              <Link
                to="/blog"
                onClick={handleNavigation}
                className="relative text-gray-900 font-medium hover:text-blue-600 transition duration-300"
              >
                Blog
                <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-blue-600 transition-all duration-300 hover:w-full" />
              </Link>
            </li>
          </ul>

          {/* "Book Demo" Button (Right) */}
          <div className="hidden lg:block">
            <Link
              to="/book-demo"
              className="ml-4 px-4 py-2 bg-blue-600 text-white rounded-md font-medium hover:bg-blue-700 transition duration-300"
            >
              Book Demo
            </Link>
          </div>

          {/* Mobile Menu Icon */}
          <div
            className="lg:hidden text-xl sm:text-2xl cursor-pointer text-gray-900"
            onClick={toggleMobileMenu}
          >
            {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
          </div>
        </div>
      </nav>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <ul className="lg:hidden absolute top-16 left-0 w-full bg-white shadow-md flex flex-col items-start px-4 py-4 space-y-4 z-30">
          <li>
            <Link
              to="/"
              onClick={handleNavigation}
              className="text-gray-900 font-medium hover:text-blue-600 transition duration-300"
            >
              Home
            </Link>
          </li>
          <li className="w-full">
            <button className="w-full flex items-center justify-between text-gray-900 font-medium hover:text-blue-600 transition duration-300">
              Solutions{' '}
              <FaChevronDown className="ml-2 transition-transform duration-300" />
            </button>
            <ul className="mt-2 pl-4 space-y-2">
              <li>
                <Link
                  to="/solutions/enterprise"
                  onClick={handleNavigation}
                  className="block text-sm text-gray-900 hover:text-blue-600 hover:bg-blue-50 rounded-md px-2 py-1 transition duration-200"
                >
                  Enterprise
                </Link>
              </li>
              <li>
                <Link
                  to="/solutions/university"
                  onClick={handleNavigation}
                  className="block text-sm text-gray-900 hover:text-blue-600 hover:bg-blue-50 rounded-md px-2 py-1 transition duration-200"
                >
                  University
                </Link>
              </li>
            </ul>
          </li>
          <li className="w-full">
            <button className="w-full flex items-center justify-between text-gray-900 font-medium hover:text-blue-600 transition duration-300">
              Company{' '}
              <FaChevronDown className="ml-2 transition-transform duration-300" />
            </button>
            <ul className="mt-2 pl-4 space-y-2">
              <li>
                <Link
                  to="/company/about"
                  onClick={handleNavigation}
                  className="block text-sm text-gray-900 hover:text-blue-600 hover:bg-blue-50 rounded-md px-2 py-1 transition duration-200"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/company/contact"
                  onClick={handleNavigation}
                  className="block text-sm text-gray-900 hover:text-blue-600 hover:bg-blue-50 rounded-md px-2 py-1 transition duration-200"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link
              to="/blog"
              onClick={handleNavigation}
              className="text-gray-900 font-medium hover:text-blue-600 transition duration-300"
            >
              Blog
            </Link>
          </li>
          <li className="w-full">
            <Link
              to="/book-demo"
              className="w-full px-4 py-2 bg-blue-600 text-white rounded-md font-medium hover:bg-blue-700 transition duration-300 text-center"
            >
              Book Demo
            </Link>
          </li>
        </ul>
      )}
    </>
  );
};

export default Navbar;
