// src/App.js
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import theme from './Theme';
import Navbar from './components/Navbar';
import HeroSection from './components/Hero';
import TechExpoSlider from './components/TechExpoSlider';
import BookDemo from './components/BookDemo';
import HighlightSection from './components/HighlightSection';
import RiskElimination from './components/RiskElimination';
import Blog from './components/Blog';
import BlogPost from './components/BlogPost';
import Enterprise from './components/Enterprise';
import Footer from "./components/Footer";
import DesignPartnerProgram from './components/DesignPartnerProgram';
import SEOPage from './components/seo/SEOPage';
import TakeAction from './components/TakeAction';
import routeTitles from './routeTitles'; // Import the route-to-title mapping
import TitleManager from './components/TitleManager'; // Import the TitleManager

import './App.css';
import './index.css';

// Lazy load components
const UniversityPage = lazy(() => import('./components/UniversityPage'));
const About = lazy(() => import('./components/About')); // Ensure path is correct
const Contact = lazy(() => import('./components/Contact')); // Ensure path is correct

// Home Component
const Home = () => (
  <div className="home-container">
    <HeroSection />
    <div className="main-content">
      <div className="content-section">
        <TechExpoSlider />
        <HighlightSection />
        <RiskElimination />
        <TakeAction />
      </div>
    </div>
  </div>
);

// Solutions Component with Outlet for Nested Routes
const Solutions = () => (
  <div className="content">
    <Outlet />
  </div>
);

// Company Component with Outlet for Nested Routes
const Company = () => (
  <div className="content">
    <Outlet />
  </div>
);

// App Component with Updated Routes and Footer
function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <TitleManager />
        <div className="relative min-h-screen">
          {/* Content */}
          <div className="relative z-10">
            {/* Navbar */}
            <Navbar />
            
            {/* Main content area with flex-grow to take available space */}
            <main className="flex-grow">
              <Suspense fallback={<div className="text-center my-8">Loading...</div>}>
                <Routes>
                  {/* Home Route */}
                  <Route path="/" element={<Home />} />

                  {/* Solutions Routes with Nested Routes */}
                  <Route path="/solutions" element={<Solutions />}>
                    <Route path="enterprise" element={<Enterprise />} />
                    <Route path="university" element={<UniversityPage />} />
                  </Route>

                  {/* Company Routes with Nested Routes */}
                  <Route path="/company" element={<Company />}>
                    <Route path="about" element={<About />} />
                    <Route path="contact" element={<Contact />} />
                  </Route>

                  {/* Other Routes */}
                  <Route path="/blog" element={<Blog />} />
                  <Route path="/blog/:postId" element={<BlogPost />} />
                  <Route path="/book-demo" element={<BookDemo />} />
                  <Route path="/design-partner" element={<DesignPartnerProgram />} />
                  <Route path="/seo" element={<SEOPage />} />
                </Routes>
              </Suspense>
            </main>
            
            {/* Footer */}
            <Footer />
          </div>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
